import React, { useState, useEffect,useRef } from 'react';
import { Link } from 'react-router-dom';
import './astonstyle.css'; // Import your custom styles if needed


const NavBar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState('Menu'); // Default selected menu item
  const initialRender = useRef(true);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleMenuClick = (menu) => {
    setSelectedMenu(menu);
    setIsMenuOpen(false); // Close the menu after selecting an item (if it's open)
  };

  
const smoothScroll = (targetY, duration) => {
  const startY = window.scrollY;
  const distanceY = targetY - startY;
  const startTime = performance.now();

  const easeInOutQuad = (t) => {
    return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
  };

  const scroll = () => {
    const currentTime = performance.now();
    const timeElapsed = (currentTime - startTime) / duration;
    const progress = Math.min(timeElapsed, 1);
    const ease = easeInOutQuad(progress);

    window.scrollTo(0, startY + distanceY * ease);

    if (handleMenuClick < 1) {
      requestAnimationFrame(scroll);
    }
  };

  scroll();
};
  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else if (selectedMenu) {
      smoothScroll(window.scrollY + 200, 500); // Adjust the targetY and duration as needed
    }
  }, [selectedMenu]);

  return (
    <>
      {/* Navigation Bar */}
      <nav className="nav" style={{color:'#414141', backgroundColor:'#00215b'}}>
        <div className="container container-nav">
          {/* Left side items */}
          <div className="nav-item nav-item-left">
            <Link to="/" className={`nav-link ${selectedMenu === 'Menu' ? '' : 'w--current'}`} onClick={() => handleMenuClick('Menu')}>
              <div className="nav-link-text-outer-wrapper">
                <div className="nav-link-text-inner-wrapper">
                  <div className="nav-link-text">Menu</div>
                  <div className="nav-link-text-absolute">Menu</div>
                </div>
              </div>
            </Link>
            <Link to="/promotion" className={`nav-link ${selectedMenu === 'Promotion' ? '' : 'w--current'}`} onClick={() => handleMenuClick('Promotion')}>
              <div className="nav-link-text-outer-wrapper">
                <div className="nav-link-text-inner-wrapper">
                  <div className="nav-link-text">Promotion</div>
                  <div className="nav-link-text-absolute">Monthly Promotion</div>
                </div>
              </div>
            </Link>
            <Link to="/Food" className={`nav-link ${selectedMenu === 'Food' ? '' : 'w--current'}`} onClick={() => handleMenuClick('Food')}>
              <div className="nav-link-text-outer-wrapper">
                <div className="nav-link-text-inner-wrapper">
                  <div className="nav-link-text">Food</div>
                  <div className="nav-link-text-absolute">Food</div>
                </div>
              </div>
            </Link>
            <Link to="/Beverages" className={`nav-link ${selectedMenu === 'Beverages' ? '' : 'w--current'}`} onClick={() => handleMenuClick('Beverages')}>
              <div className="nav-link-text-outer-wrapper">
                <div className="nav-link-text-inner-wrapper">
                  <div className="nav-link-text">Beverages</div>
                  <div className="nav-link-text-absolute">Beverages</div>
                </div>
              </div>
            </Link>
          </div>
          {/* Middle logo */}
          <div className="nav-item nav-item-mid">
            <Link to="/" className="nav-brand w-inline-block">
              <img
                src="../images/LogoAstonBgBiru.png"
                alt="Aston"
                loading="lazy"
                style={{ width: '100%', height: '100%' }}
              />
            </Link>
          </div>

          {/* Right side items */}
          <div className="nav-item nav-item-right">
            <Link to="/bookmarked" className="nav-link w-inline-block">
              <div className="nav-link-text-outer-wrapper">
                <div className="nav-link-text-inner-wrapper">
                  <div className="nav-link-text">Bookmark</div>
                  <div className="nav-link-text-absolute">Bookmark</div>
                </div>
              </div>
            </Link>
          </div>

          {/* Menu button (if needed) */}
          <div className="nav-menu-button" onClick={toggleMenu}>
            <div>Menu</div>
          </div>
        </div>
      </nav>

      {/* Hero section */}
      <header className="section-hero section-hero-menu">
        <div className="container">
          <div className="title title-center-hero">
            <p className="display-2xs">Restaurant</p>
            <div className="hero-heading-line">
              <h1 className="display-2xl display-2xl-hero">Aston</h1>
            </div>
            <p className="paragraph-thick">{selectedMenu}</p>
            <img
            src={`../images/${selectedMenu}.jpeg`}
            loading="lazy"
            alt="Aston Logo"
            className="nav-menu-logo"
          />
          </div>
        </div>
      </header>
      

      {/* Example of nav-menu for responsive menu */}
      <div className={`nav-menu ${isMenuOpen ? 'w--open' : ''}`}>
        <div className="nav-menu-wrapper" >
          <div className="nav-link-responsive-wrapper">
            <Link to="/" className={`nav-link-responsive ${selectedMenu === 'Menu' ? 'w--current' : ''}`} onClick={() => handleMenuClick('Menu')}>
              Menu
            </Link>
          </div>
          <div className="nav-link-responsive-wrapper">
            <Link to="/promotion" className={`nav-link-responsive ${selectedMenu === 'Promotion' ? 'w--current' : ''}`} onClick={() => handleMenuClick('Promotion')}>
              Promotion
            </Link>
          </div>
          <div className="nav-link-responsive-wrapper">
            <Link to="/Food" className={`nav-link-responsive ${selectedMenu === 'Food' ? 'w--current' : ''}`} onClick={() => handleMenuClick('Food')}>
              Food
            </Link>
          </div>
          <div className="nav-link-responsive-wrapper">
            <Link to="/Beverages" className={`nav-link-responsive ${selectedMenu === 'Beverages' ? 'w--current' : ''}`} onClick={() => handleMenuClick('Beverages')}>
              Beverages
            </Link>
          </div>
          <div className="nav-link-responsive-wrapper">
            <Link to="/Bookmarked" className="nav-link-responsive">
              Bookmarked
            </Link>
          </div>
        </div>
        <div className="nav-menu-logo-wrapper">
          <img
            src="../images/LogoAston.png"
            loading="lazy"
            alt="Aston Logo"
            className="nav-menu-logo"
          />
        </div>
        <div className="nav-menu-button-wrapper">
          <div className="nav-menu-close-button" onClick={toggleMenu}>
            Close
          </div>
        </div>
      </div>
    </>
  );
};

export default NavBar;
