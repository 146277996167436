const Menu = [
    {
      id: 1,
      image: "images/GordonGin.jpg",
      name: "Gordon Gin",
      category: "Alcohol",
      price: "RP. 20.000",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Blanditiis, at consectetur totam voluptatibus quibusdam iusto. Accusamus quas, soluta ipsam autem eius necessitatibus fugiat in . ",
    },
  
    {
      id: 2,
      image: "images/allupakoida.jpg",
      name: "allu pakoida",
      category: "Coffee",
      price: "50.000",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Blanditiis, at consectetur totam voluptatibus quibusdam iusto. Accusamus quas, soluta ipsam autem eius necessitatibus fugiat in . ",
    },
    {
      id: 3,
      image: "images/corn.jpg",
      name: "Alcohol",
      category: "breakfast",
      price: "Rp 30.000",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Blanditiis, at consectetur totam voluptatibus quibusdam iusto. Accusamus quas, soluta ipsam autem eius necessitatibus fugiat in . ",
    },
   
  ];
  
  export default Menu;