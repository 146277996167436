import React, { useState } from 'react'
import Menu from '../menuApiAtaya';
import MenuCard from './MenuCard';
import Navbar from './Navbar';

const uniqueList= [
  ...new Set(Menu.map((curElem) =>{
  return curElem.category;
})
),"All"
];

console.log(uniqueList);

const RestaurantAtaya = () => {
  
   const [menuData, setMenuData ]= useState(Menu);
   const [menuList] =useState(uniqueList);

   //setMenuList gw ilangin

   const filterItem=(category)=>{
    
   if(category==="All"){
    setMenuData(Menu);
    return;
   }

    const updatedList =Menu.filter((curElem) =>{
      return curElem.category===category;
    });
     setMenuData(updatedList);
   };
   
  return (
    <>
   
    <Navbar filterItem ={filterItem} menuList={menuList}/>
    <MenuCard menuData={menuData}/>
    </>
  )
}

export default RestaurantAtaya 
