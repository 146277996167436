const Menu = [
    {
      id: 1,
      image: "images/maggi.jpg",
      name: "maggi",
      category: "MAIN COURSE",
      price: "20",
      description:
        "I love Maggi realy oo yues  Lorem ipsum dolor sit amet consectetur adipisicing elit. . ",
    },
  
    {
      id: 2,
      image: "images/allupakoida.jpg",
      name: "allu pakoida",
      category: "EVENING",
      price: "50",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. . ",
    },
    {
      id: 3,
      image: "images/corn.jpg",
      name: "corn",
      category: "BREAKFAST",
      price: "30",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. . ",
    },
    {
      id: 4,
      image: "../images/chola.jpg",
      name: "chola",
      category: "LUNCH",
      price: "30",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. . ",
    },
    {
      id: 5,
      image: "../images/pizza.jpg",
      name: "pizza",
      category: "EVENING",
      price: "30",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. . ",
    },
    {
      id: 6,
      image: "../images/nonvegthali.jpg",
      name: "Non-Veg Thali",
      category: "DINNER",
      price: "130",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. . ",
    },
    {
      id: 7,
      image: "../images/sweet.jpg",
      name: "Sweets",
      category: "DINNER",
      price: "30",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. . ",
    },
    {
      id: 8,
      image: "../images/rajmarice.jpg",
      name: "Rajma Rice",
      category: "LUNCH",
      price: "30",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. . ",
    },
    {
      id: 9,
      image: "../images/samosa.jpg",
      name: "samaso",
      category: "EVENING",
      price: "30",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. . ",
    },
    
  ];
  
  export default Menu;