const Menu = [
  {
    id: 1,
    image: "images/GordonGin.jpg",
    name: "Gin",
    category: "ALCOHOL",
    price: "20",
    description:
      "A classic and versatile spirit, perfect for a variety of cocktails and mixed drinks. Known for its distinct juniper flavor.",
  },
  {
    id: 2,
    image: "images/allupakoida.jpg",
    name: "Allu Pakoida",
    category: "EVENING",
    price: "50",
    description:
      "Crispy and flavorful potato fritters, a popular evening snack that pairs perfectly with tea or coffee.",
  },
  {
    id: 3,
    image: "images/corn.jpg",
    name: "Jagungs",
    category: "BREAKFAST",
    price: "30",
    description:
      "Freshly grilled corn on the cob, seasoned with a blend of spices for a tasty and healthy breakfast option.",
  },
  {
    id: 4,
    image: "../images/chola.jpg",
    name: "Chola",
    category: "LUNCH",
    price: "30",
    description:
      "A hearty and flavorful chickpea curry, best enjoyed with rice or bread. A perfect choice for a satisfying lunch.",
  },
  {
    id: 5,
    image: "../images/pizza.jpg",
    name: "Pizza",
    category: "EVENING",
    price: "30",
    description:
      "A classic favorite, our pizza is topped with the freshest ingredients and baked to perfection. Great for sharing with friends.",
  },
  {
    id: 6,
    image: "../images/nonvegthali.jpg",
    name: "Non-Veg Thali",
    category: "DINNER",
    price: "30",
    description:
      "An assortment of non-vegetarian dishes served with rice and bread. A complete and fulfilling dinner experience.",
  },
  {
    id: 7,
    image: "../images/sweet.jpg",
    name: "Sweets",
    category: "DINNER",
    price: "30",
    description:
      "A selection of traditional sweets to satisfy your dessert cravings. Perfect for ending your meal on a sweet note.",
  },
  {
    id: 8,
    image: "../images/rajmarice.jpg",
    name: "Rajma Rice",
    category: "LUNCH",
    price: "30",
    description:
      "A classic North Indian dish made with kidney beans and rice, cooked in a rich and flavorful gravy. Ideal for a comforting lunch.",
  },
  {
    id: 9,
    image: "../images/samosa.jpg",
    name: "Samosa",
    category: "EVENING",
    price: "30",
    description:
      "Golden and crispy pastry filled with a spicy potato mixture. A popular snack that's perfect for any time of the day.",
  },
];

export default Menu;
