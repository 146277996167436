import React from "react";
import './astonstyle.css';

const MenuCard = ({ menuData }) => {
  return (
    <section className="main-card--container">
      {menuData.map((curElem) => {
        const { id, name, category, price, description } = curElem;

        return (
          <section class="section">
          <div class="container">
              <div class="w-layout-grid grid grid-menu">
                  <div id="appetizers" class="list-heading w-node-_7a6b66d1-f2dc-3374-f480-9fd457bea429-28b1bd48">
                      <h2 class="display-2xs">Appetizers</h2>
                  </div>
                  <div id="w-node-b7012d92-0993-b25b-73ea-5aedb0eb97d4-28b1bd48" class="menu-list-wrapper">
                      <div class="w-dyn-list">
                          <div role="list" class="w-dyn-items">
                              <div role="listitem" class="w-dyn-item">
                                  <a href="/menu-item/spinach-and-artichoke-dip" class="menu-category-list-item w-inline-block">
                                      <div class="menu-category-list-item-details">
                                          <div class="title title-s">
                                              <div class="display-m">Spinach and Artichoke Dip</div>
                                              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p>
                                          </div>
                                      </div>
                                      <div class="menu-item-price">
                                          <div class="display-2xs">$</div>
                                          <div class="display-2xs">8</div>
                                      </div><img src="./images/icon/arrowkananatas.svg" loading="lazy" alt="" class="icon-m icon-m-menu-item" /><img src="./images/item/spinach.jpg"
                                          loading="lazy" alt="" class="menu-category-list-item-image" /></a>
                              </div>
                              <div role="listitem" class="w-dyn-item">
                                  <a href="/menu-item/caprese-salad" class="menu-category-list-item w-inline-block">
                                      <div class="menu-category-list-item-details">
                                          <div class="title title-s">
                                              <div class="display-m">Caprese Salad</div>
                                              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p>
                                          </div>
                                      </div>
                                      <div class="menu-item-price">
                                          <div class="display-2xs">$</div>
                                          <div class="display-2xs">12</div>
                                      </div>
                                      <img src="./images/icon/arrowkananatas.svg" loading="lazy" alt="" class="icon-m icon-m-menu-item" /><img src="./images/item/salad.jpg"
                                          loading="lazy" alt="" class="menu-category-list-item-image" /></a>
                              </div>
                              <div role="listitem" class="w-dyn-item">
                                  <a href="/menu-item/shrimp-cocktail" class="menu-category-list-item w-inline-block">
                                      <div class="menu-category-list-item-details">
                                          <div class="title title-s">
                                              <div class="display-m">Shrimp Cocktail</div>
                                              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p>
                                          </div>
                                      </div>
                                      <div class="menu-item-price">
                                          <div class="display-2xs">$</div>
                                          <div class="display-2xs">14</div>
                                      </div><img src="./images/icon/arrowkananatas.svg" loading="lazy" alt="" class="icon-m icon-m-menu-item" /><img src="./images/item/shrimp.jpg"
                                          loading="lazy" alt="" class="menu-category-list-item-image" /></a>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div class="container">
              <div class="w-layout-grid grid grid-menu">
                  <div id="main-courses" class="list-heading w-node-_882f69b7-4857-8dd8-087c-049c80c5467f-28b1bd48">
                      <h2 class="display-2xs">Main Courses</h2>
                  </div>
                  <div id="w-node-_882f69b7-4857-8dd8-087c-049c80c54683-28b1bd48" class="menu-list-wrapper">
                      <div class="w-dyn-list">
                          <div role="list" class="w-dyn-items">
                              <div role="listitem" class="w-dyn-item">
                                  <a href="/menu-item/beef-tenderloin-steak" class="menu-category-list-item w-inline-block">
                                      <div class="menu-category-list-item-details">
                                          <div class="title title-s">
                                              <div class="display-m">Beef Tenderloin Steak</div>
                                              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p>
                                          </div>
                                      </div>
                                      <div class="menu-item-price">
                                          <div class="display-2xs">$</div>
                                          <div class="display-2xs">30</div>
                                      </div><img src="./images/icon/arrowkananatas.svg" loading="lazy" alt="" class="icon-m icon-m-menu-item" /><img src="./images/item/steak.jpg"
                                          loading="lazy" alt="" class="menu-category-list-item-image" /></a>
                              </div>
                              <div role="listitem" class="w-dyn-item">
                                  <a href="/menu-item/grilled-salmon" class="menu-category-list-item w-inline-block">
                                      <div class="menu-category-list-item-details">
                                          <div class="title title-s">
                                              <div class="display-m">Grilled Salmon</div>
                                              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p>
                                          </div>
                                      </div>
                                      <div class="menu-item-price">
                                          <div class="display-2xs">$</div>
                                          <div class="display-2xs">23</div>
                                      </div><img src="./images/icon/arrowkananatas.svg" loading="lazy" alt="" class="icon-m icon-m-menu-item" /><img src="./images/item/salmon.jpg"
                                          loading="lazy" alt="" class="menu-category-list-item-image" /></a>
                              </div>
                              <div role="listitem" class="w-dyn-item">
                                  <a href="/menu-item/vegetarian-pasta-primavera" class="menu-category-list-item w-inline-block">
                                      <div class="menu-category-list-item-details">
                                          <div class="title title-s">
                                              <div class="display-m">Vegetarian Pasta Primavera</div>
                                              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p>
                                          </div>
                                      </div>
                                      <div class="menu-item-price">
                                          <div class="display-2xs">$</div>
                                          <div class="display-2xs">32</div>
                                      </div><img src="./images/icon/arrowkananatas.svg" loading="lazy" alt="" class="icon-m icon-m-menu-item" /><img src="./images/item/pasta.jpg"
                                          loading="lazy" alt="" class="menu-category-list-item-image" /></a>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div class="container">
              <div class="w-layout-grid grid grid-menu">
                  <div id="side-dishes" class="list-heading w-node-_8b973bb6-0cef-df67-4239-d74d040e064f-28b1bd48">
                      <h2 class="display-2xs">Side Dishes</h2>
                  </div>
                  <div id="w-node-_8b973bb6-0cef-df67-4239-d74d040e0653-28b1bd48" class="menu-list-wrapper">
                      <div class="w-dyn-list">
                          <div role="list" class="w-dyn-items">
                              <div role="listitem" class="w-dyn-item">
                                  <a href="/menu-item/garlic-parmesan-roasted-brussels-sprouts" class="menu-category-list-item w-inline-block">
                                      <div class="menu-category-list-item-details">
                                          <div class="title title-s">
                                              <div class="display-m">Garlic Parmesan Roasted Brussels Sprouts</div>
                                              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p>
                                          </div>
                                      </div>
                                      <div class="menu-item-price">
                                          <div class="display-2xs">$</div>
                                          <div class="display-2xs">12</div>
                                      </div><img src="./images/icon/arrowkananatas.svg" loading="lazy" alt="" class="icon-m icon-m-menu-item" /><img src="./images/item/brussel-sprouts.jpg"
                                          loading="lazy" alt="" class="menu-category-list-item-image" /></a>
                              </div>
                              <div role="listitem" class="w-dyn-item">
                                  <a href="/menu-item/loaded-baked-potato" class="menu-category-list-item w-inline-block">
                                      <div class="menu-category-list-item-details">
                                          <div class="title title-s">
                                              <div class="display-m">Loaded Baked Potato</div>
                                              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p>
                                          </div>
                                      </div>
                                      <div class="menu-item-price">
                                          <div class="display-2xs">$</div>
                                          <div class="display-2xs">14</div>
                                      </div><img src="./images/icon/arrowkananatas.svg" loading="lazy" alt="" class="icon-m icon-m-menu-item" /><img src="./images/item/baked-potato.jpg"
                                          loading="lazy" alt="" class="menu-category-list-item-image" /></a>
                              </div>
                              <div role="listitem" class="w-dyn-item">
                                  <a href="/menu-item/quinoa-pilaf" class="menu-category-list-item w-inline-block">
                                      <div class="menu-category-list-item-details">
                                          <div class="title title-s">
                                              <div class="display-m">Quinoa Pilaf</div>
                                              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p>
                                          </div>
                                      </div>
                                      <div class="menu-item-price">
                                          <div class="display-2xs">$</div>
                                          <div class="display-2xs">11</div>
                                      </div><img src="./images/icon/arrowkananatas.svg" loading="lazy" alt="" class="icon-m icon-m-menu-item" /><img src="./images/item/quinoa.jpg"
                                          loading="lazy" alt="" class="menu-category-list-item-image" /></a>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div class="container">
              <div class="w-layout-grid grid grid-menu">
                  <div id="beverages" class="list-heading w-node-d64ae92b-b526-b80d-d038-d0bc21fdf4ea-28b1bd48">
                      <h2 class="display-2xs">Beverages</h2>
                  </div>
                  <div id="w-node-d64ae92b-b526-b80d-d038-d0bc21fdf4ee-28b1bd48" class="menu-list-wrapper">
                      <div class="w-dyn-list">
                          <div role="list" class="w-dyn-items">
                              <div role="listitem" class="w-dyn-item">
                                  <a href="/menu-item/freshly-squeezed-orange-juice" class="menu-category-list-item w-inline-block">
                                      <div class="menu-category-list-item-details">
                                          <div class="title title-s">
                                              <div class="display-m">Freshly Squeezed Orange Juice</div>
                                              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p>
                                          </div>
                                      </div>
                                      <div class="menu-item-price">
                                          <div class="display-2xs">$</div>
                                          <div class="display-2xs">5</div>
                                      </div><img src="./images/icon/arrowkananatas.svg" loading="lazy" alt="" class="icon-m icon-m-menu-item" /><img src="./images/item/orange-juice.jpg"
                                          loading="lazy" alt="" class="menu-category-list-item-image" /></a>
                              </div>
                              <div role="listitem" class="w-dyn-item">
                                  <a href="/menu-item/iced-tea" class="menu-category-list-item w-inline-block">
                                      <div class="menu-category-list-item-details">
                                          <div class="title title-s">
                                              <div class="display-m">Iced Tea</div>
                                              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p>
                                          </div>
                                      </div>
                                      <div class="menu-item-price">
                                          <div class="display-2xs">$</div>
                                          <div class="display-2xs">4</div>
                                      </div><img src="./images/icon/arrowkananatas.svg" loading="lazy" alt="" class="icon-m icon-m-menu-item" /><img src="./images/item/ice-tea.jpg"
                                          loading="lazy" alt="" class="menu-category-list-item-image" /></a>
                              </div>
                              <div role="listitem" class="w-dyn-item">
                                  <a href="/menu-item/classic-mojito" class="menu-category-list-item w-inline-block">
                                      <div class="menu-category-list-item-details">
                                          <div class="title title-s">
                                              <div class="display-m">Classic Mojito</div>
                                              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p>
                                          </div>
                                      </div>
                                      <div class="menu-item-price">
                                          <div class="display-2xs">$</div>
                                          <div class="display-2xs">8</div>
                                      </div><img src="./images/icon/arrowkananatas.svg" loading="lazy" alt="" class="icon-m icon-m-menu-item" /><img src="./images/item/mojito.jpg"
                                          loading="lazy" alt="" class="menu-category-list-item-image" /></a>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div class="container">
              <div class="w-layout-grid grid grid-menu">
                  <div id="desserts" class="list-heading w-node-b56ba51c-21ac-568c-fd23-ef9e22c19269-28b1bd48">
                      <h2 class="display-2xs">Desserts</h2>
                  </div>
                  <div id="w-node-b56ba51c-21ac-568c-fd23-ef9e22c1926d-28b1bd48" class="menu-list-wrapper">
                      <div class="w-dyn-list">
                          <div role="list" class="w-dyn-items">
                              <div role="listitem" class="w-dyn-item">
                                  <a href="/menu-item/mango-sorbet" class="menu-category-list-item w-inline-block">
                                      <div class="menu-category-list-item-details">
                                          <div class="title title-s">
                                              <div class="display-m">Mango Sorbet</div>
                                              <p>Mango Sorbet</p>
                                          </div>
                                      </div>
                                      <div class="menu-item-price">
                                          <div class="display-2xs">$</div>
                                          <div class="display-2xs">12</div>
                                      </div><img src="./images/icon/arrowkananatas.svg" loading="lazy" alt="" class="icon-m icon-m-menu-item" /><img src="./images/item/sorbet.jpg"
                                          loading="lazy" alt="" class="menu-category-list-item-image" /></a>
                              </div>
                              <div role="listitem" class="w-dyn-item">
                                  <a href="/menu-item/new-york-cheesecake" class="menu-category-list-item w-inline-block">
                                      <div class="menu-category-list-item-details">
                                          <div class="title title-s">
                                              <div class="display-m">New York Cheesecake</div>
                                              <p>New York Cheesecake</p>
                                          </div>
                                      </div>
                                      <div class="menu-item-price">
                                          <div class="display-2xs">$</div>
                                          <div class="display-2xs">13</div>
                                      </div><img src="./images/icon/arrowkananatas.svg" loading="lazy" alt="" class="icon-m icon-m-menu-item" /><img src="./images/item/cheesecake.jpg"
                                          loading="lazy" alt="" class="menu-category-list-item-image" /></a>
                              </div>
                              <div role="listitem" class="w-dyn-item">
                                  <a href="/menu-item/chocolate-lava-cake" class="menu-category-list-item w-inline-block">
                                      <div class="menu-category-list-item-details">
                                          <div class="title title-s">
                                              <div class="display-m">Chocolate Lava Cake</div>
                                              <p>Chocolate Lava Cake</p>
                                          </div>
                                      </div>
                                      <div class="menu-item-price">
                                          <div class="display-2xs">150</div>
                                          <div class="display-2xs">K</div>
                                      </div>
                                      <img src="./images/icon/arrowkananatas.svg" 
                                      loading="lazy" alt="" class="icon-m icon-m-menu-item" /><img src="./images/item/cheesecake.jpg"
                                      loading="lazy" alt="" class="menu-category-list-item-image" />
                                     </a>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </section>
        );
      })}
    </section>
  );
};

export default MenuCard;
