import React from "react";
import { Link } from "react-router-dom";
import './astonstyle.css';


const Footer = () => {


return(
<footer class="section section-footer">
<div class="container">
    <div class="w-layout-grid grid grid-footer">
        {/* <div id="w-node-_49160295-3c52-5cde-45e7-5ea421453d57-21453d4c" class="w-layout-vflex footer-item">
            <h2 class="display-xs">Pages</h2>
            <ul role="list" class="w-list-unstyled">
                <li>
                    <a href="/" class="nav-link nav-link-white w-inline-block">
                        <div class="nav-link-text-outer-wrapper">
                            <div class="nav-link-text-inner-wrapper">
                                <div class="nav-link-text">Home</div>
                                <div class="nav-link-text-absolute">Home</div>
                            </div>
                        </div>
                    </a>
                </li>
                <li>
                    <a href="/about" class="nav-link nav-link-white w-inline-block">
                        <div class="nav-link-text-outer-wrapper">
                            <div class="nav-link-text-inner-wrapper">
                                <div class="nav-link-text">About</div>
                                <div class="nav-link-text-absolute">About</div>
                            </div>
                        </div>
                    </a>
                </li>
                <li>
                    <a href="#" class="nav-link nav-link-white w-inline-block">
                        <div class="nav-link-text-outer-wrapper">
                            <div class="nav-link-text-inner-wrapper">
                                <div class="nav-link-text">Contact</div>
                                <div class="nav-link-text-absolute">Contact</div>
                            </div>
                        </div>
                    </a>
                </li>
                <li>
                    <a href="/menu" aria-current="page" class="nav-link nav-link-white w-inline-block w--current">
                        <div class="nav-link-text-outer-wrapper">
                            <div class="nav-link-text-inner-wrapper">
                                <div class="nav-link-text">Menu</div>
                                <div class="nav-link-text-absolute">Menu</div>
                            </div>
                        </div>
                    </a>
                </li>
                <li>
                    <a href="/menu" aria-current="page" class="nav-link nav-link-white w-inline-block w--current">
                        <div class="nav-link-text-outer-wrapper">
                            <div class="nav-link-text-inner-wrapper">
                                <div class="nav-link-text">Reservation</div>
                                <div class="nav-link-text-absolute">Reservation</div>
                            </div>
                        </div>
                    </a>
                </li>
            </ul>
        </div> */}
        {/* <div id="w-node-_49160295-3c52-5cde-45e7-5ea421453d70-21453d4c" class="w-layout-vflex footer-item">
            <h2 class="display-xs">Admin</h2>
            <ul role="list" class="w-list-unstyled">
                <li>
                    <a href="/admin/style-guide" class="nav-link nav-link-white w-inline-block">
                        <div class="nav-link-text-outer-wrapper">
                            <div class="nav-link-text-inner-wrapper">
                                <div class="nav-link-text">Style Guide</div>
                                <div class="nav-link-text-absolute">Style Guide</div>
                            </div>
                        </div>
                    </a>
                </li>
                <li>
                    <a href="/admin/style-guide" class="nav-link nav-link-white w-inline-block">
                        <div class="nav-link-text-outer-wrapper">
                            <div class="nav-link-text-inner-wrapper">
                                <div class="nav-link-text">Instructions</div>
                                <div class="nav-link-text-absolute">Instructions</div>
                            </div>
                        </div>
                    </a>
                </li>
                <li>
                    <a href="/admin/style-guide" class="nav-link nav-link-white w-inline-block">
                        <div class="nav-link-text-outer-wrapper">
                            <div class="nav-link-text-inner-wrapper">
                                <div class="nav-link-text">Licenses</div>
                                <div class="nav-link-text-absolute">Licenses</div>
                            </div>
                        </div>
                    </a>
                </li>
                <li>
                    <a href="/admin/style-guide" class="nav-link nav-link-white w-inline-block">
                        <div class="nav-link-text-outer-wrapper">
                            <div class="nav-link-text-inner-wrapper">
                                <div class="nav-link-text">Changelog</div>
                                <div class="nav-link-text-absolute">Changelog</div>
                            </div>
                        </div>
                    </a>
                </li>
                <li>
                    <a href="/admin/style-guide" class="nav-link nav-link-white w-inline-block">
                        <div class="nav-link-text-outer-wrapper">
                            <div class="nav-link-text-inner-wrapper">
                                <div class="nav-link-text">Components</div>
                                <div class="nav-link-text-absolute">Components</div>
                            </div>
                        </div>
                    </a>
                </li>
            </ul>
        </div> */}
        <div id="w-node-_49160295-3c52-5cde-45e7-5ea421453d4f-21453d4c" class="w-layout-vflex footer-item">
            <h2 class="display-xs">Opening hours</h2>
            <div class="opening-hours-list">
                <div class="opening-hours-list-item opening-hours-list-item-white">
                    <div class="display-2xs">Monday — Thursday</div>
                    <div class="display-2xs">8 AM - 9 PM</div>
                </div>
                <div class="opening-hours-list-item opening-hours-list-item-white">
                    <div class="display-2xs">Friday — Saturday</div>
                    <div class="display-2xs">8 AM - 11 PM</div>
                </div>
                <div class="opening-hours-list-item opening-hours-list-item-white">
                    <div class="display-2xs">Sunday</div>
                    <div class="display-2xs">Closed</div>
                </div>
            </div>
        </div>
        <div id="w-node-_49160295-3c52-5cde-45e7-5ea421453d86-21453d4c" class="line line-white"></div>
        <div id="w-node-_49160295-3c52-5cde-45e7-5ea421453d87-21453d4c" class="footer-item">
            <p>© Aston Sentul  — 2024. All rights reserved. Powered by <a href="https://creadoccourse.com" target="_blank" class="link-underline link-white">Creadoc</a>.</p>
        </div>
        <div id="w-node-_88f7b9e0-957b-9d69-4302-2397dbc7611b-21453d4c" class="footer-item">
            <a href="https://www.instagram.com/astonsentul/" class="nav-link nav-link-white w-inline-block">
                <div class="nav-link-text-outer-wrapper">
                    <div class="nav-link-text-inner-wrapper">
                        <div class="nav-link-text">Instagram</div>
                        <div class="nav-link-text-absolute">Instagram</div>
                    </div>
                </div>
            </a>
            <a href="https://www.facebook.com/astonsentul/" class="nav-link nav-link-white w-inline-block">
                <div class="nav-link-text-outer-wrapper">
                    <div class="nav-link-text-inner-wrapper">
                        <div class="nav-link-text">Facebook</div>
                        <div class="nav-link-text-absolute">Facebook</div>
                    </div>
                </div>
            </a>
            <a href="https://www.tiktok.com/@astonsentul" class="nav-link nav-link-white w-inline-block">
                <div class="nav-link-text-outer-wrapper">
                    <div class="nav-link-text-inner-wrapper">
                        <div class="nav-link-text">Tiktok</div>
                        <div class="nav-link-text-absolute">Tiktok</div>
                    </div>
                </div>
            </a>
            <a href="https://www.astonhotelsinternational.com/id/hotel/view/35/aston-sentul-lake-resort---conference-center" class="nav-link nav-link-white w-inline-block">
                <div class="nav-link-text-outer-wrapper">
                    <div class="nav-link-text-inner-wrapper">
                        <div class="nav-link-text">Website</div>
                        <div class="nav-link-text-absolute">Website</div>
                    </div>
                </div>
            </a>
        </div>
        <div id="w-node-bf8e57fc-35e8-6c2a-1df4-b163872478e9-21453d4c" class="line line-white"></div>
    </div>
</div>
</footer>

)

};
export default Footer;