const Menu = [
 
    {
      id: 5,
      image: "../images/SignatureDish.jpg",
   },
    {
      id: 6,
      image: "../images/FromTheGrill.jpg",
   },
    {
      id: 7,
      image: "../images/Pasta.jpg",
   },
    {
      id: 8,
      image: "../images/Desserts.jpg",
   },
    {
      id: 9,
      image: "../images/Desserts.jpg",
   },
    {
      id: 10  ,
      image: "../images/Desserts.jpg",
   },
  ];
  
  export default Menu;