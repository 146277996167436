import React, { useState } from "react";

const MenuCardPromotion = ({ menuData }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedImage(null);
  };

  return (
    <>
      <section className="main-card-promotion--container">
        {menuData.map((curElem) => {
          const { id, image } = curElem;

          return (
            <div className="card-promotion-container" key={id}>
              <div className="cardPromotion">
        
                <img
                  src={image}
                  alt="images"
                  className="card-media"
                  onClick={() => handleImageClick(image)}
                  />
                  </div>
              </div>
          );
        })}
      </section>

      {isModalOpen && (
        <div className="modal" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <span className="close" onClick={closeModal}>&times;</span>
            <img src={selectedImage} alt="Full Size" className="modal-image" />
          </div>
        </div>
      )}
    </>
  );
};

export default MenuCardPromotion;
