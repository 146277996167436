import React, { useState } from 'react'
import Menu from '../menuApiPromotion';
import MenuCard from './MenuCardPromotion';
// import Navbar from './Navbar';

const uniqueList= [
  ...new Set(Menu.map((curElem) =>{
  return curElem.category;
})
),"All"
];

console.log(uniqueList);

const RestaurantPromotion = () => {
  
   const [menuData ]= useState(Menu);


   // Kalo filter mau dinyalain nyalain bawahnya >>> 

//    const [menuList] =useState(uniqueList);

   //setMenuList gw ilangin

//    const filterItem=(category)=>{
    
//    if(category==="All"){
//     setMenuData(Menu);
//     return;
//    }

//     const updatedList =Menu.filter((curElem) =>{
//       return curElem.category===category;
//     });
//      setMenuData(updatedList);
//    };
   
  return (
    <>
   
    {/* <Navbar filterItem ={filterItem} menuList={menuList}/> */}
    <MenuCard menuData={menuData}/>
    </>
  )
}

export default RestaurantPromotion 
