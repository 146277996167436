import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Resturant from './Components/Basics/Resturant';
import NavBar2 from './Components/Basics/NavBar2';
import RestaurantAtaya from './Components/Basics/RestaurantAtaya';
import RestaurantPromotion from './Components/Basics/RestaurantPromotion';
import RestaurantAwal from './Components/Basics/RestaurantAwal';
import LoadingSpinner from './Components/Basics/loading'; // Import the LoadingSpinner component
import Footer from './Components/Basics/footer';
import RestaurantFood from './Components/Basics/RestaurantFood';
import RestaurantBeverages from './Components/Basics/RestaurantBeverages';

const App = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate a loading delay (e.g., fetch data, load resources)
    setTimeout(() => {
      setLoading(false);
    }, 2000); // Adjust the delay time as needed
  }, []);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <Router>
      <NavBar2 />
      <Routes>
        <Route path="/" element={<RestaurantAwal />} />
        <Route path="/Promotion" element={<RestaurantPromotion />} />
        <Route path="/Food" element={<RestaurantFood />} />
        <Route path="/Beverages" element={<RestaurantBeverages />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
